<template>
  <div :class="['equipment-board', 'col-start-center', isWxMobileClass]">
    <van-sticky style="width: 100%" v-if="isWxMobileClass">
      <van-tabs background="#171d36" color="#16baff" title-inactive-color="#8e9fb6" title-active-color="#fff" v-model="tabActive">
        <van-tab title="待修门店"></van-tab>
        <van-tab title="重点关注任务"></van-tab>
        <van-tab title="数据看板"></van-tab>
      </van-tabs>
    </van-sticky>

    <header class="flex-none row-between-center" v-else>
      <div class="date-box row-start-center">
        <p class="p1 BebasNeueBold">{{ curTime }}</p>
        <p class="p2">{{ curDate }}</p>
        <p class="p3">{{ curWeekDay }}</p>
      </div>
      <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/word.png" alt />
      <div v-if="!isMobile" class="check-box row-start-center" @click="handCheck">
        <span class="check row-center-center">
          <van-icon v-show="isHandCheck" name="success" />
        </span>
        手动切换
      </div>
    </header>

    <main :class="['flex-auto', 'row-center-start', isWxMobileClass]">
      <div v-show="whatShow.includes('left')" class="left flex-none col-start-center common-bg" :class="isWxMobileClass">
        <h3 class="title fw6">报修门店</h3>
        <div class="sub-title row-between-center cyan">
          <span>待修门店/部门</span>
          <span>待处理数</span>
        </div>
        <div v-if="fixStoreList.length" class="content scroll-box flex-auto">
          <ul>
            <li class="store-item row-between-center" v-for="item in fixStoreList">
              <div class="fw5 ellipsis">{{ item.venueName }}</div>
              <div class="fw6 BebasNeueBold row-center-center">
                <p>
                  {{ item.pendingCount }}
                  <img v-if="item.isRemind" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/flash-icon.png" alt />
                  <img v-if="item.isWarning" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/att-icon.png" alt />
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div v-else class="content flex-auto row-center-center">暂无待修门店</div>
      </div>
      <div v-show="whatShow.includes('mid')" :class="['mid', 'flex-auto', 'col-start-center', isWxMobileClass]">
        <h3 class="title fw6">重点关注任务</h3>
        <div class="city-box row-center-center">
          <div class="city-swiper">
            <swiper v-if="cityList.length" ref="citySwiper" :options="citySwiperOption">
              <swiper-slide v-for="item in cityList">
                <p class="city-item">{{ item.areaName }}</p>
              </swiper-slide>
            </swiper>
          </div>
          <div :class="['attention-tips', 'row-start-center', isWxMobileClass]">
            <div class="tip-item row-start-center">
              <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/flash-icon.png" alt />
              <span class="BebasNeueBold">{{ importantTask.remindNum }}</span>
            </div>
            <div class="tip-item row-start-center">
              <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/att-icon.png" alt />
              <span class="BebasNeueBold">{{ importantTask.warningNum }}</span>
            </div>
          </div>
        </div>
        <div v-if="importantTask.contents.length" :class="['content', 'col-start-center', 'flex-auto', isWxMobileClass]">
          <swiper ref="swiper" :options="swipeOption" style="height: 100%; flex: auto; width: 100%">
            <swiper-slide @click.native="edit(item)" class="row-between-start" v-for="(item, index) in importantTask.contents" :key="index">
              <div style="height: 100%" :class="['flex-auto', isWxMobileClass ? 'col-around-start' : 'col-center-start']">
                <h3 class="name fw6 row-start-center">
                  {{ item.venueName }}
                  <span v-if="item.completeCost >= 5" :class="['tag', 'row-center-center', item.completeCost >= 7 ? '' : 'yellow']">已耗时{{ item.completeCost }}天</span>
                </h3>
                <div :class="['place', isWxMobileClass ? '' : 'row-start-center']">
                  <div :class="['place-item', 'row-start-center', isWxMobileClass]">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/loc-icon.png" alt />
                    <span class="fw6 ellipsis">位置：{{ item.location }}</span>
                  </div>
                  <div :class="['place-item', 'row-start-center', isWxMobileClass]">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/com-icon.png" alt />
                    <span class="fw6 ellipsis">设备：{{ item.deviceName }}</span>
                  </div>
                </div>
                <p :class="['question', 'fw5', isWxMobileClass ? '' : 'ellipsis-2', 'flex-none']">
                  {{ item.content }}
                </p>
                <p class="desc light-blue fw5" v-if="item.unusualRemark">说明：{{ item.unusualRemark }}</p>
                <p class="who cyan">申请人：{{ item.applyerName || '--' }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提交时间：{{ item.applyTimeStr || '--' }}</p>
              </div>
              <!-- 当前节点 -->
              <div :class="['progress', 'flex-none', isWxMobileClass]" v-show="item.currentApprover.length">
                <div class="title row-start-center">
                  <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/man-icon.png" alt />
                  <p class="cyan">当前处理人</p>
                </div>
                <div>
                  <div class="people row-start-center" v-for="item in item.currentApprover">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/ma-icon.png" alt />
                    <span class="fw6 ellipsis">{{ item }}</span>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="content flex-auto row-center-center" v-if="noImportantTask">暂无重点关注任务</div>
      </div>
      <div v-show="whatShow.includes('right')" :class="['right', 'scroll-box', 'flex-none', 'col-start-center', isWxMobileClass]">
        <div class="col-start-center common-bg">
          <h3 class="title fw6">任务监控告警</h3>
          <div class="att-box row-between-start">
            <div class="att-item col-start-center" v-for="item in boardSummary.cityWarningList">
              <img :src="`https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/${item.warningNum ? 'ring' : 'right'}-icon.png`" alt />
              <p class="fw6">{{ item.cityName }}</p>
              <span v-show="item.warningNum">{{ item.warningNum }}条异常</span>
            </div>
          </div>
        </div>
        <div class="col-start-center common-bg">
          <h3 class="title fw6">当前处理任务情况</h3>
          <div class="cur-solve">
            <div class="row-start-center">
              <span style="opacity: 0"></span>
              <span class="cyan">当前处理中</span>
              <span class="cyan">本月新增</span>
              <span class="cyan">本月完成</span>
            </div>
            <div class="item row-start-center" v-for="item in boardSummary.cityCurrentTaskList">
              <span class="fw6" style="font-size: 0.16rem">{{ item.cityName }}</span>
              <span style="font-size: 0.28rem" :class="[item.currentNum ? 'blue' : '', 'BebasNeueBold']">{{ item.currentNum }}</span>
              <span style="font-size: 0.28rem" :class="[item.currentMonthNew ? 'red' : '', 'BebasNeueBold']">{{ item.currentMonthNew }}</span>
              <span style="font-size: 0.28rem" :class="[item.currentMonthFinish ? 'green' : '', 'BebasNeueBold']">{{ item.currentMonthFinish }}</span>
            </div>
          </div>
        </div>
        <div class="col-start-center common-bg">
          <h3 class="title fw6">上月完成情况</h3>
          <div class="last-solve">
            <div class="row-start-center">
              <span style="opacity: 0"></span>
              <span class="cyan">上月完成</span>
              <span class="cyan">7天完成率</span>
              <span class="cyan">平均处理时长</span>
            </div>

            <div class="item row-start-center" v-for="item in boardSummary.cityLastMonthTaskList">
              <span class="fw6" style="font-size: 0.16rem">{{ item.cityName }}</span>
              <span style="font-size: 0.28rem" class="green BebasNeueBold">{{ item.lastMonthFinishNum }}</span>
              <span style="font-size: 0.28rem" class="BebasNeueBold">
                {{ item.sevenDayFinishRate }}
                <i style="font-size: 0.16rem; font-style: normal">%</i>
              </span>
              <span style="font-size: 0.28rem" class="BebasNeueBold">
                {{ item.averageCost }}
                <i style="font-size: 0.14rem; font-style: normal">天</i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-start-center common-bg" style="padding-bottom: 0.28rem">
          <h3 class="title fw6">任务完成时长分布</h3>
          <div class="complete-solve">
            <div class="example row-center-center">
              <div class="item row-start-center">
                <span class="card green-bg"></span>
                <span class="p1 cyan">7天</span>
              </div>
              <div class="item row-start-center">
                <span class="card blue-bg"></span>
                <span class="p1 cyan">15天</span>
              </div>
              <div class="item row-start-center">
                <span class="card orange-bg"></span>
                <span class="p1 cyan">30天</span>
              </div>
              <div class="item row-start-center">
                <span class="card red-bg"></span>
                <span class="p1 cyan">大于30天</span>
              </div>
            </div>
            <div class="rate">
              <div class="item row-start-center" v-for="item in boardSummary.cityFinishTimeDistributeList">
                <span class="name fw6">{{ item.cityName }}</span>
                <div class="bar flex-auto row-start-center">
                  <span v-if="item.sevenRate !== 0" class="green-bg" :style="{ width: item.sevenRate + '%' }">
                    <i>{{ item.sevenRate }}%</i>
                  </span>
                  <span v-if="item.fifteenRate !== 0" class="blue-bg" :style="{ width: item.fifteenRate + '%' }">
                    <i>{{ item.fifteenRate }}%</i>
                  </span>
                  <span v-if="item.thirtyRate !== 0" class="orange-bg" :style="{ width: item.thirtyRate + '%' }">
                    <i>{{ item.thirtyRate }}%</i>
                  </span>
                  <span v-if="item.gtThirtyRate !== 0" class="red-bg" :style="{ width: item.gtThirtyRate + '%' }">
                    <i>{{ item.gtThirtyRate }}%</i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 详情 编辑 弹窗 -->
    <van-popup closeable class="edit-dialog" v-model="editShow">
      <div class="content col-start-center scroll-box">
        <div class="edit-top row-between-center">
          <div class="row-start-center">
            <p class="p1">审批编号：{{ curItem.formData.spNo }}</p>
            <p class="p2" @click="copy">复制</p>
          </div>
          <div v-if="hasPermission" class="button fw6 light-blue row-center-center" @click="openUnusual">异常说明</div>
          <div v-else></div>
        </div>
        <div class="edit-mid col-around-start">
          <h3 class="name fw6 row-start-center">
            {{ curItem.formData.venueName }}
            <span v-if="curItem.formData.completeCost >= 5" :class="['tag', 'row-center-center', curItem.formData.completeCost >= 7 ? '' : 'yellow']">已耗时{{ curItem.formData.completeCost }}天</span>
          </h3>
          <div :class="['place', isWxMobileClass ? '' : 'row-start-center']">
            <div :class="['place-item', 'row-start-center', isWxMobileClass]">
              <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/loc-icon.png" alt />
              <span class="fw6 ellipsis">位置：{{ curItem.formData.location }}</span>
            </div>
            <div :class="['place-item', 'row-start-center', isWxMobileClass]">
              <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/com-icon.png" alt />
              <span class="fw6 ellipsis">设备：{{ curItem.formData.deviceName }}</span>
            </div>
          </div>
          <p :class="['question', 'fw5', isWxMobileClass ? '' : 'ellipsis-2', 'flex-none']">
            {{ curItem.formData.content }}
          </p>
          <p class="desc light-blue fw5" v-if="curItem.formData.unusualRemark">说明：{{ curItem.formData.unusualRemark }}</p>
          <p class="who cyan">申请人：{{ curItem.formData.applyerName || '--' }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提交时间：{{ curItem.formData.applyTimeStr || '--' }}</p>
        </div>
        <div class="edit-bottom" v-show="curItem.comments.length">
          <p class="name">备注</p>
          <div class="comment row-between-start" v-for="comment in curItem.comments">
            <div class="man">
              <div class="head row-start-center">
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/ma-icon.png" alt />
                <span class="fw6">{{ comment.name }}</span>
              </div>
              <p class="speech">{{ comment.commentContent }}</p>
            </div>
            <div class="status row-start-center flex-none">
              <p class="p1 fw5">{{ comment.commentTime }}</p>
            </div>
          </div>
        </div>
        <div class="edit-bottom" v-show="curItem.spRecords.length">
          <p class="name">审批流程</p>
          <div :class="['dots', dot.spStatus === 1 ? 'fail' : '']" v-for="dot in curItem.spRecords">
            <div class="people row-between-start" v-for="(people, index) in dot.details">
              <div class="man">
                <div class="head row-start-center">
                  <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/ma-icon.png" alt />
                  <span class="fw6">{{ people.name }}</span>
                </div>
                <p class="speech">{{ people.speech }}</p>
              </div>

              <div class="status row-start-center flex-none">
                <p :class="['p1', 'fw5', dot.spStatus === 1 ? 'fail' : '']">
                  {{ dot.spStatus === 1 || index == 0 ? people.spStatusText : '' }}
                </p>
                <p class="p2">{{ index == 0 ? people.spTime : '' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 异常说明 -->
    <van-popup class="common-dialog" v-model="unusualShow">
      <div class="content col-start-center">
        <p class="title fw6">异常说明</p>
        <p class="desc">为异常任务添加说明备注</p>
        <div class="input-box row-center-center">
          <input class="flex-auto" v-model="unusualItem.unusualRemark" placeholder="限制20字" type="text" maxlength="20" />
        </div>
        <div class="buttons row-around-center">
          <div class="button row-center-center" @click="unusualShow = false">取消</div>
          <div class="button row-center-center" @click="submit">保存</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { getParam, isWeixin } from '@/lib/utils'
import { isMobile } from '@/lib/appMethod'

const changeTime = 6000

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      citySwiperOption: {
        initialSlide: 1,
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        speed: 600,
        slideToClickedSlide: true,
        on: {
          slideChangeTransitionEnd: async () => {
            this.cityId = this.citySwiper ? this.cityList[this.citySwiper.realIndex].cityId : '3'
            this.getImportantTask()
            this.getBoardSummary()
          },
        },
      },
      swipeOption: {
        autoplay: {
          delay: changeTime,
          disableOnInteraction: false,
          stopOnLastSlide: true,
        },
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        direction: 'vertical',
        slidesPerView: isWeixin() && isMobile() ? 'auto' : 3,
        slidesPerGroup: isWeixin() && isMobile() ? 1 : 3,
        freeMode: isWeixin() && isMobile() ? true : false,
        spaceBetween: 8,
        speed: 400,
        autoHeight: true,
        mousewheel: true,
        on: {
          reachEnd: () => {
            if (!(this.isWx || this.isHandCheck)) {
              setTimeout(() => {
                this.citySwiper.autoplay.start()
              }, changeTime)
            }
          },
        },
      },
      isHandCheck: false, // 是否手动切换

      curDate: '', // 当前日期
      curTime: '', // 当前时间
      curWeekDay: '', // 当前周几

      curItem: {
        // 当前详情
        formData: {},
        spRecords: [],
        comments: [],
      },
      unusualItem: {}, // 当前添加异常
      unusualShow: false,

      isWx: false,
      isMobile: false,
      hasPermission: false,

      cityId: '3',

      fixStoreList: [], // 待修门店列表
      cityList: [], // 城市列表
      boardSummary: {}, // 看板
      importantTask: { contents: [] }, // 重点项目
      noImportantTask: false,

      tabActive: 0, // 微信移动端 tab

      editShow: false,
    }
  },
  computed: {
    citySwiper() {
      return this.$refs.citySwiper.swiper
    },
    swiper() {
      return this.$refs.swiper.swiper
    },
    isWxMobileClass() {
      return this.isWx && this.isMobile ? 'mobile' : '' // 真的
      // return (this.isMobile) ? 'mobile' : '' // 暂用
    },
    whatShow() {
      if (this.isWxMobileClass) {
        return [['left', 'mid', 'right'][this.tabActive]]
      }
      return ['left', 'mid', 'right']
    },
  },
  watch: {
    isHandCheck(v) {
      setTimeout(() => {
        if (v) {
          this.citySwiper.autoplay.stop()
          this.swiper ? this.swiper.autoplay.stop() : null
        } else {
          this.citySwiper.autoplay.start()
          this.swiper ? this.swiper.autoplay.start() : null
        }
      }, 1000)
    },
  },
  async created() {
    this.$toast.loading({ forbidClick: true, loadingType: 'spinner', overlay: true })
    // 是否是移动端
    this.isMobile = isMobile()
    // 是否是微信
    this.isWx = isWeixin()
    // 初始化 是否是手动切换
    this.isHandCheck = isWeixin()
    // 授权登录
    this.authorzeLogin()
    // 时间
    setInterval(this.getTime, 1000)
    // 城市列表
    await this.getCity()
    // 报修门店
    setInterval(this.getFixStore(), 60 * 1000 * 60)
    // 重点关注任务
    this.getImportantTask()
    // 数据看板
    this.getBoardSummary()
  },

  methods: {
    async authorzeLogin() {
      if (!this.isWx) return
      const code = getParam().code
      // 链接中没有code 需要跳转微信授权地址
      if (!code) {
        let loginInfo = { appId: 3, appSecret: 'c95c9f78-6c4b-42d2-8644-477b5e547dbf' }
        let href = location.href

        this.$axios.post(this.baseURLApp + '/wxcp/web/auth/url', Object.assign(loginInfo, { redirectUri: href })).then((r) => {
          window.location.replace(r.data)
        })
        return
      }
      let token = sessionStorage.getItem('qywx-token')
      // 没有token 调登录接口获取
      if (!token) {
        token = await this.login(code)
        sessionStorage.setItem('qywx-token', token)
      }
      axios.defaults.headers['authorization'] = token
      this.checkUser()
    },
    login(code) {
      let loginInfo = { appId: 3, appSecret: 'c95c9f78-6c4b-42d2-8644-477b5e547dbf' }
      return this.$axios.post(this.baseURLApp + '/wxcp/web/auth/login', Object.assign(loginInfo, { code, agentId: '1000056' })).then((res) => {
        return res.data
      })
    },
    // 校验人员
    checkUser() {
      this.$axios.post(this.baseURLApp + '/approval/judgeCanRemark').then((res) => {
        this.hasPermission = res.code == 1 ? true : false
      })
    },
    // 待修门店
    getFixStore() {
      this.$axios.post(this.baseURLApp + '/approval/underRepairVenue').then((res) => {
        this.fixStoreList = res.data
      })
      return this.getFixStore
    },

    // 获取城市
    getCity() {
      return this.$axios.post(this.baseURLApp + '/venue/scheduleLessonCityList').then((res) => {
        this.cityList = res.data
      })
    },

    // 重点任务
    getImportantTask() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.importantTask.contents = []
      this.$axios.post(this.baseURLApp + '/approval/importantTaskShow', { cityId: this.cityId }).then((res) => {
        this.importantTask = res.data
        this.noImportantTask = this.importantTask.contents.length ? false : true
        this.importantTask.contents.map((item) => {
          item.currentApprover = item.currentApprover ? item.currentApprover.split(';') : []
        })
        if (res.data.contents.length === 0) {
          this.citySwiper.autoplay.start()
        } else {
          this.citySwiper.autoplay.stop()
        }

        this.$toast.clear()
        if (this.isHandCheck) {
          setTimeout(() => {
            this.$refs.swiper.swiper.autoplay.stop()
          }, 1000)
        }
      })
    },

    getBoardSummary() {
      this.$axios.post(this.baseURLApp + '/approval/approvalStat').then((res) => {
        this.boardSummary = res.data
      })
    },
    getTime() {
      let time = new Date()
      let month = time.getMonth() + 1
      let year = time.getFullYear()
      let day = time.getDate()
      let hours = time.getHours()
      let minutes = time.getMinutes()
      let seconds = time.getSeconds()
      let weekDay = time.getDay()

      this.curDate = `${year}.${('0' + month).slice(-2)}.${('0' + day).slice(-2)}`
      this.curWeekDay = `周${['', '一', '二', '三', '四', '五', '六', '日'][weekDay]}`
      this.curTime = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`
    },
    handCheck() {
      this.isHandCheck = !this.isHandCheck
    },
    openUnusual() {
      this.unusualItem = JSON.parse(JSON.stringify(this.curItem.formData))
      this.$set(this.unusualItem, 'unusualRemark', '')
      this.unusualShow = true
    },
    edit(item) {
      this.curId = item.spNo
      this.getImportantDetail()
      this.editShow = true
    },
    getImportantDetail() {
      this.$axios.post(this.baseURLApp + '/approval/repairDetail', { spNo: this.curId }).then((res) => {
        this.curItem = res.data
      })
    },
    copy() {
      this.$copyText(this.curItem.formData.spNo).then(
        (e) => {
          this.tips('已复制到剪贴板')
        },
        (e) => {
          this.tips('复制失败')
        }
      )
    },
    // 添加说明
    submit() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/approval/unusualRemark', {
          unusualRemark: this.unusualItem.unusualRemark,
          spNo: this.unusualItem.spNo,
        })
        .then((res) => {
          this.$toast.clear()
          if (res.code == 1) {
            this.$toast('添加成功！')
            this.unusualShow = false
            this.getImportantDetail()
            this.getImportantTask()
          }
        })
    },
  },
}
</script>
<style lang="less">
html {
  font-size: calc(100vh / 1080 * 100);
  background: #0c0c0d;
  * {
    box-sizing: border-box;
  }
  *::-webkit-scrollbar {
    display: none;
  }
}

.equipment-board {
  width: 100%;
  height: 100vh;
  font-size: 0.18rem;
  color: #fff;
  margin: 0 auto;
  &.mobile {
    height: auto;
    min-height: 100vh;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  header {
    width: 100%;
    height: 1rem;
    max-height: 100px;
    background-color: #151a30;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/head-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.42rem 0.32rem 0;
    position: relative;
    .date-box {
      color: #c0d5ec;
      .p1 {
        font-size: 0.4rem;
      }
      .p2 {
        font-size: 0.2rem;
        margin: 0 0.36rem;
      }
      .p3 {
        font-size: 0.2rem;
      }
    }
    img {
      width: 2.33rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .check-box {
      color: #c0d5ec;
      font-size: 0.2rem;
      cursor: pointer;
      .check {
        width: 0.22rem;
        height: 0.22rem;
        border-radius: 0.02rem;
        border: 0.02rem solid #0e477e;
        margin: 0 0.08rem 0 0;
        .van-icon {
          font-weight: 600;
        }
      }
    }
  }

  main {
    max-width: 1920px;
    width: 100%;
    padding: 0.2rem 0 0.4rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    overflow: hidden;
    &.mobile {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0.1rem;
    }
    .left,
    .mid,
    .right {
      min-height: calc(100vh - 1.6rem);
      &.mobile {
        width: 100%;
        min-height: auto;
        height: 100%;
        margin: 0.06rem 0 0;
      }
    }
    .left,
    .mid,
    .right > div {
      border-radius: 12px;
      border: 2px solid #0e4579;
      padding: 0.18rem;
      background-color: #171d36;
    }
    .left {
      width: 3.68rem;
      max-width: 368px;
      .content {
        width: 100%;
        padding: 0.16rem 0 0;
      }
      .sub-title {
        width: 100%;
        margin: 0.32rem 0 0.16rem;
        span {
          width: 50%;
          text-align: center;
          font-size: 0.14rem;
        }
      }
      .store-item {
        width: 100%;
        margin: 0 0 0.3rem;
        div {
          width: 50%;
          text-align: center;
          &:nth-child(2) {
            font-size: 0.24rem;
            p {
              position: relative;
              img {
                width: 0.48rem;
                position: absolute;
                top: -0.13rem;
                &:nth-child(1) {
                  right: -0.48rem;
                }
                &:nth-child(2) {
                  right: -0.8rem;
                }
              }
            }
          }
        }
      }
    }

    .mid {
      padding: 0.18rem 0 0;
      margin: 0 0.16rem;
      max-width: 1018px;
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/mid-bg.jpg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      .city-box {
        width: 100%;
        margin: 0.32rem 0 0;
        position: relative;
        border-bottom: 0.01rem solid #0c0c0d;
        .city-swiper {
          width: 4rem;
          .swiper-slide {
            .city-item {
              padding: 0.24rem 0;
              text-align: center;
              transition: all 0.3s;
              transform-origin: center;
              color: #8e9fb6;
            }
          }
          .swiper-slide-active {
            &::after {
              content: '';
              width: 66%;
              height: 0.04rem;
              border-radius: 0.02rem;
              background-color: #16baff;
              position: absolute;
              bottom: 0;
              left: 17%;
            }
            .city-item {
              transform: scale(1.23);
              font-weight: 600;
              color: #fff;
            }
          }
        }
        .attention-tips {
          position: absolute;
          right: 0.34rem;
          bottom: 0.1rem;
          margin: 0 0 0 0.24rem;
          &.mobile {
            right: 0.2rem;
            bottom: 0.92rem;
          }
          .tip-item {
            img {
              width: 0.54rem;
              margin: 0 -0.04rem 0 0;
            }
            span {
              font-size: 0.28rem;
              margin: 0.06rem 0 0;
            }
          }
        }
      }

      .content {
        width: 100%;
        padding: 0.24rem 0.32rem;
        overflow: hidden;
        &.mobile {
          min-height: 460px;
          padding: 0.1rem;
        }

        .swiper-slide {
          width: 100%;
          padding: 0.16rem;
          background: #1b223e;
          cursor: pointer;
          border-radius: 0.12rem;

          .name {
            font-size: 0.22rem;
            line-height: 1;

            .tag {
              padding: 0.04rem 0.08rem;
              line-height: 1;
              margin: 0 0 0 0.12rem;
              border-radius: 0.04rem;
              color: #f26848;
              border: 0.01rem solid #f26848;
              background: rgba(237, 92, 66, 0.1);
              font-size: 0.16rem;

              &.yellow {
                background: rgba(162, 135, 24, 0.1);
                border: 0.01rem solid #ad8f00;
                color: #ad8f00;
              }
            }
          }

          .place {
            margin: 0.2rem 0 0.17rem;

            .place-item {
              margin: 0 0.7rem 0 0;

              &.mobile {
                margin: 0.08rem 0;
              }

              img {
                width: 0.28rem;
                margin: 0 0.08rem 0 0;
              }

              span {
                font-size: 0.22rem;
                max-width: 2.8rem;
              }
            }
          }

          .question {
            word-break: break-all;
            font-size: 0.18rem;
          }

          .desc {
            font-size: 0.16rem;
            margin: 0.14rem 0 0;
            word-break: break-all;
          }

          .who {
            font-size: 0.16rem;
            margin: 0.16rem 0 0;
          }

          .progress {
            min-width: 1.4rem;

            .title {
              img {
                width: 0.2rem;
              }

              p {
                font-size: 0.16rem;
                margin: 0 0 0 0.08rem;
              }
            }

            .people {
              padding: 0.17rem;
              margin: 0.04rem 0 0 0.09rem;
              border-left: 0.02rem solid #6aa9d4;

              img {
                width: 0.24rem;
              }

              span {
                font-size: 0.16rem;
                margin: 0 0 0 0.08rem;
              }
            }
          }
        }
      }
    }

    .right {
      width: 4.35rem;
      max-width: 435px;

      &.mobile {
        width: 100%;
        max-width: 100%;
      }

      & > div {
        width: 100%;
        flex: auto;

        &:not(:first-child) {
          margin: 0.06rem 0 0;
        }

        & > .title {
          margin: 0 0 0.12rem;
        }
      }

      .att-box {
        width: 100%;
        padding: 0 0.08rem;

        .att-item {
          img {
            width: 0.88rem;
          }

          span {
            font-size: 0.14rem;
            color: #bbb;
            margin: 0.04rem 0 0;
          }
        }
      }

      .cur-solve,
      .last-solve {
        width: 100%;
        text-align: center;

        .item {
          margin: 0.12rem 0 0;
        }

        span {
          width: 25%;
          font-size: 0.14rem;
        }
      }

      .complete-solve {
        width: 100%;

        .example {
          .item {
            margin: 0 0.11rem 0;

            .card {
              width: 0.16rem;
              height: 0.08rem;
            }

            .p1 {
              font-size: 0.14rem;
              margin: 0 0 0 0.06rem;
            }
          }
        }

        .rate {
          margin: 0.12rem 0 0;

          .item {
            margin: 0.18rem 0 0;

            .name {
              font-size: 0.16rem;
              text-align: right;
            }

            .bar {
              height: 0.1rem;
              margin: 0 0 0 0.12rem;
              border-radius: 0.62rem;
              background-color: #6c727a;

              // overflow: hidden;
              span {
                width: 25%;
                height: 100%;

                &:not(:first-child) {
                  border-left: 0.02rem solid #f5f5f5;
                }

                &:first-child {
                  border-radius: 0.62rem 0 0 0.62rem;
                }

                &:last-child {
                  border-radius: 0 0.62rem 0.62rem 0;
                }

                position: relative;

                i {
                  font-style: normal;
                  font-size: 0.12rem;
                  position: absolute;
                  bottom: -0.2rem;
                  left: 50%;
                  transform: translateX(-50%);

                  &:nth-child(even) {
                    bottom: auto;
                    top: -0.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .common-bg {
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/equipment-board/co-bg.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }

    .scroll-box {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .cyan {
    color: #8e9fb6;
  }

  .orange {
    color: #ed5c43;
  }

  .orange-bg {
    background-color: #ff8a30;
  }

  .green {
    color: #31cc43;
  }

  .green-bg {
    background-color: #31cc43;
  }

  .blue {
    color: #16baff;
  }

  .blue-bg {
    background-color: #16baff;
  }

  .light-blue {
    color: #6aa9d4;
  }

  .red {
    color: #f85438;
  }

  .red-bg {
    background-color: #f85438;
  }
}

.common-dialog {
  width: 4rem;
  border-radius: 0.1rem;
  padding: 0.28rem 0.2rem 0.2rem;
  box-sizing: border-box;

  .content {
    .title {
      color: #242831;
      font-size: 0.22rem;
    }

    .desc {
      margin: 0.12rem 0 0;
      font-size: 0.16rem;
      color: #6c727a;
    }

    .input-box {
      width: 100%;
      margin: 0.21rem 0 0;

      input {
        height: 0.5rem;
        background: #f1f4f9;

        color: #242831;
        border-radius: 0.06rem;
        padding: 0 0.15rem;
        border: none;

        &::placeholder {
          color: #9aa1a9;
        }
      }
    }

    .buttons {
      width: 100%;
      margin: 0.3rem 0 0;

      .button {
        width: 1.4rem;
        height: 0.5rem;
        background: #3c454e;
        border-radius: 0.08rem;
        color: #fff;
        cursor: pointer;

        &:nth-child(1) {
          color: rgba(60, 69, 78, 1);
          background: transparent;
          border: 1px solid #dddddd;
        }
      }
    }
  }
}

.edit-dialog {
  width: 95%;
  max-width: 8rem;
  height: 90vh;
  border-radius: 0.1rem;
  padding: 0.28rem 0.2rem 0.2rem;
  box-sizing: border-box;
  color: #242831;

  .content {
    padding: 0.6rem 0 0;

    & > div {
      width: 100%;
    }

    .edit-top {
      font-size: 0.18rem;

      .p1 {
        color: #6c727a;
        margin: 0 0.1rem 0 0;
      }

      .p2 {
        cursor: pointer;
      }

      .button {
        width: 1.15rem;
        height: 0.4rem;
        border-radius: 0.04rem;
        border: 0.01rem solid #6aa9d4;
        cursor: pointer;
      }
    }

    .edit-mid {
      width: 100%;
      margin: 0.2rem 0 0;
      padding: 0 0 0.2rem;
      border-bottom: 1px solid #f6f7fb;

      .name {
        font-size: 0.24rem;
        line-height: 1;

        .tag {
          padding: 0.04rem 0.08rem;
          line-height: 1;
          margin: 0 0 0 0.12rem;
          border-radius: 0.04rem;
          color: #f26848;
          border: 0.01rem solid #f26848;
          background: rgba(237, 92, 66, 0.1);
          font-size: 0.18rem;

          &.yellow {
            background: rgba(162, 135, 24, 0.1);
            border: 0.01rem solid #ad8f00;
            color: #ad8f00;
          }
        }
      }

      .place {
        margin: 0.2rem 0 0.17rem;

        .place-item {
          margin: 0 0.7rem 0 0;

          &.mobile {
            margin: 0.08rem 0;
          }

          img {
            width: 0.28rem;
            margin: 0 0.08rem 0 0;
          }

          span {
            font-size: 0.22rem;
            max-width: 2.5rem;
          }
        }
      }

      .question {
        word-break: break-all;
        font-size: 0.2rem;
      }

      .desc {
        font-size: 0.18rem;
        margin: 0.14rem 0 0;
        word-break: break-all;
      }

      .who {
        font-size: 0.18rem;
        margin: 0.16rem 0 0;
      }
    }

    .edit-bottom {
      padding: 0.2rem 0;

      .name {
        font-size: 0.24rem;
        margin: 0 0 0.2rem;
      }

      .dots {
        position: relative;
        padding: 0 0.17rem 0.34rem;
        margin: 0 0 0 0.12rem;
        border-left: 0.02rem solid #6aa9d4;

        &::before {
          content: '';
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background-color: #6aa9d4;
          position: absolute;
          top: 0;
          left: -0.11rem;
        }

        &.fail {
          border-left: 0.02rem solid #c8c9cc;

          &::before {
            background-color: #c8c9cc;
          }
        }

        .people {
          padding: 0 0 0.17rem;
          margin: 0 0 0 0.09rem;
        }
      }

      .comment {
        margin: 0 0 0.34rem;
      }

      .head {
        img {
          width: 0.24rem;
        }

        span {
          font-size: 0.2rem;
          margin: 0 0 0 0.08rem;
        }
      }

      .speech {
        margin: 0.1rem 0 0;
      }

      .status {
        .p1 {
          &.fail {
            color: #6aa9d4;
          }
        }

        .p2 {
          margin: 0 0 0 0.08rem;
          color: #6c727a;
        }
      }
    }
  }
}
</style>
